.container {
  display: flex;
  height: 100%;
}

.panel {
  margin: auto;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 320px;
}

.error {
  width: 100%;
  margin: 0;
}
