.picker {
  position: relative;
}

.rangePicker {
  display: block;
  padding: 10px 0 9px;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  background: #f6f7fa;
  border: 0;
  border-radius: 8px;
  padding-left: 39px;
  /* text-align: center; */
  color: var(--secondary-text-color);
  cursor: pointer;
  min-width: 230px;
}

.rangePicker::placeholder {
  color: rgb(33, 33, 33, 0.6);
}

.clearBtn {
  position: absolute !important;
  padding: 0 !important;
  right: 0 !important;
}

.clearBtn::after {
  background: transparent;
  color: var(--primary-color) !important;
  font-size: 24px !important;
  width: 32px;
  height: 32px;
}

.calendar_icon {
  position: absolute;
  display: block;
  top: 6px;
  left: 12px;
  pointer-events: none;
}

.popper div {
  font-family: "Gilroy", sans-serif;
  font-size: 12px;
  text-transform: capitalize;
}
