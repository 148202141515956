.columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}
.column {
  display: flex;
}

.status{
  align-items: center;
  position: relative;
  justify-content: end;
  bottom: 30px;
  display: flex;
}

.description {
  margin-top: 20px;
  margin-bottom: 16px;
}

.description_ref {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer {
  margin: 5px 0 0;
  display: flex;
  justify-content: flex-end;
}
.link{
  /*align-items: end;*/
  /*position: absolute;*/
  /*right: 70px;*/
  margin-right: 70px;
  font-size: 14px;
  line-height: 1;
  padding: 10px 26px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid var(--primary-color);
  font-weight: bold;
  color: var(--primary-color);
}

.link:hover{
  color: #fff;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
