.root {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  gap: 40px;
  justify-content: space-between;
}
.headerWrap {

  width: 100%;
}
.descriptions {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.alldata {
  display: flex;
  align-items: center;
  text-align: center;
}

.man {
  margin-left: auto;
  margin-right: auto;
}
.additionalInfo {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.additionalInfoMan {
  flex-shrink: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.additionalInfoChart {
  flex-shrink: 0;
  width: 50%;
}
.diagram p,
.bots {
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 16px;
  color: #000000;
}

.bots {
  text-align: center;
  margin-top: 6px;
  margin-bottom: 0;
  opacity: 0.35;
}

.publications {
  max-height: 777px;
  overflow-y: auto;
  padding: 8px 24px 24px;
  margin: 12px 0 -24px -24px;

  display: flex;
  flex-direction: column;
  gap: 21px;
}

.publications::-webkit-scrollbar {
  width: 7px;
}
.publications::-webkit-scrollbar-track {
  background: hsl(228, 33%, 95%);
  border-radius: 10px;
}
.publications::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--primary-color);
}

.tonal{
  display: flex;
  gap: 15px;
}

@media (max-width: 576px) {
  .additionalInfo, .alldata {
    display: block;
  }
  .additionalInfoChart{
    width: 100%;
  }
}
