.topics {
}

.filterLine {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 40px;
}

.filterLine + .filterLine {
  margin-top: 13px;
}

.search {
  flex: 2;
  min-width: 282px;
  flex-shrink: 0;
}
.tab {
  white-space: nowrap;
  flex-grow: 1;
}
.range,
.source {
  min-width: 282px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.select {
  flex-grow: 1;
}

.label {
  margin-right: 10px;
  margin-left: 10px;
  /* margin-right: 34px; */
}

.list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;

}
