.panel {
  position: relative;
  padding: 26px;
  background: #fcfcfc;
}

.overlay {
  overflow: auto;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  line-height: 1;
  background: transparent;
  color: var(--primary-color) !important;
  font-size: 24px !important;
  width: 32px;
  height: 32px;
  border: 0;
}
