.media {
  display: flex;
  align-items: center;
}

.person {
  text-align: center;
  flex-shrink: 0;
  width: 144px;
}

.person__caption,
.person__name,
.person__subtitle,
.theme__caption,
.theme__title {
  padding: 0;
  margin: 0;
}

.person__caption,
.person__subtitle {
  text-align: left;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  opacity: 0.35;
}

.person__caption {
  margin-top: 1px;
}

.person__name {
  text-align: left;
  margin-top: 2px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: var(--primary-text-color);
  word-wrap: break-word;
}

.man {
  margin: 0;
  position: relative;
  text-align: initial;
  margin-right: 30px;
}

.theme {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin: 0 25px 0 0;
  flex-grow: 1;
}

.subject {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin: 0 25px 0 0;
  flex-grow: 1;
  max-width: 200px;
  width: 200px;
}

.theme__caption {
  margin-top: 1px;
  margin-right: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  text-transform: capitalize;
  opacity: 0.35;
}

.subject__caption {
  margin-top: 1px;
  margin-right: auto;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-transform: capitalize;
  opacity: 0.35;
  margin-bottom: 0;
}


.theme__title {
  margin-top: 2px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: var(--primary-text-color);
  color: var(--primary-darker-color);
  font-size: 16px;
  font-weight: 500;
}

.subject__title {
  margin-top: 2px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: var(--primary-text-color);
  color: var(--primary-darker-color);
  font-size: 18px;
  font-weight: 500;
}

.data {
  display: grid;
  gap: 21px;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}

.diagrams p {
  margin: 0;
  padding: 0;
}

.data p {
  padding: 0;
  margin: 0 0 4px;
  margin-bottom: 15px;
}

.public {
  display: grid;
  grid-template-columns: 39% 26% 35%;
  grid-template-rows: auto;
  column-gap: 10px;
}

.diagrams {
  padding-top: 24px;
  gap: 14px;
  display: flex;
  align-items: flex-start;
}

@media (max-width: 1400px) {
  .diagrams {
    flex-direction: column;
  }
}

.diagram {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  min-width: 200px;
  max-width: 240px;
  flex-shrink: 0;
}

.diagramPanel {
  margin-top: 7px;
  flex-grow: 1;
  height: 104px;
}

.diagramPanel {
  padding: 10px;
}

@media (max-width: 576px) {
  .theme {
    display: block;
  }
  .media {
    word-break: break-word;
  }
}
