.columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 20px;
}

.description {
  margin-top: 20px;
  margin-bottom: 16px;
}

.status{
  align-items: end;
  position: relative;
  bottom: 30px;
  right: 100px;
}

.status dd{
  position: absolute;
  right: -70px;
}
.statsInfo{
  align-items: center;
  position: relative;
  justify-content: end;
  bottom: 30px;
  display: flex;
}
