.panel {
  /* max-width: 1093px; */
}

.post {
  position: relative;
  min-height: 100%;
  padding: 26px 31px 20px 22px;
}

.media{
  display: flex;
}

.more{
  font-size: 13px;
  position: absolute;
  text-align: center;
  cursor: pointer;
  margin: 35px;
  left: 316px;
  color: #1890ff;
}

.videoicon{
  color: white;
  font-size: 35px;
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  cursor: pointer;
}

.video{
  position: relative;
  margin: auto 5px;
  margin-bottom: 0;
  height: 70px;
  display: inline-block;
  margin-left: 10px;
  overflow: hidden;
}

.statistics {
  margin-right: 5px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  padding-left: 8px;
}

.icons {
  margin-right: 8px;
}

.avatar {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}
.avatarIcon {
  position: absolute;
  top: 19px;
  left: 22px;
  width: 24px;
  height: 24px;
}

.origin {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.site,
.date,
.text,
.headline {
  margin: 0;
  padding: 0;
}

.storttext{
  margin: 0;
}

.storttext::before{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  content: '';
  display: block;
  height: 50px;
  width: 100%;
  pointer-events: none;
  background: linear-gradient(174deg, rgba(231,240,247,0.075) 0%, rgba(229,237,247,0.3) 33%, #f9f9f9 80%);
}

.site {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  color: var(--secondary-text-color);
}

.uri{
  text-decoration: none;
}

.date {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: var(--secondary-text-color);
}

.headline {
  margin-bottom: 13px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: var(--primary-color);
}

.text {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--secondary-text-color);
  position: relative;
  z-index: 1;
  width: 100%;
  word-break: break-word;
  min-height: 30px;
}

.default {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    linear-gradient(
      90.03deg,
      rgba(95, 92, 92, 0.12) 0.53%,
      rgba(95, 92, 92, 0.05) 30.37%
    );
  border-radius: 10px;
}

.positive {
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0.1), rgba(153, 255, 102, 0.4)), linear-gradient(90.03deg, rgba(0, 224, 22, 0.07) 0.3%, rgba(0, 224, 22, 0) 42.37%);
  border-radius: 10px;
}

.negative {
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0.1), rgba(255, 51, 0, 0.12)), linear-gradient(90.03deg, rgba(0, 224, 22, 0.07) 0.3%, rgba(0, 224, 22, 0) 42.37%);
  border-radius: 10px;
}

.showMore {
  white-space: nowrap;
  cursor: pointer;
  color: var(--primary-color);
}

.show{
  z-index: 6;
  font-size: 13px;
  position: relative;
  text-align: center;
  cursor: pointer;
  margin: 18px;
  color: #1890ff;
  position: absolute;
  right: 35px;
  width: 100px;
  bottom: -16px;
  left: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s linear;
}

.textcontain{
  position: relative;
}

.footer {
  margin: 5px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn {
  margin-left: auto;
}
.footerBtn {
  font-size: 14px;
  padding: 0px 7px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: #4ec0e4;
  border-color: #4ec0e4;
  line-height: 1.5715;
  text-decoration: none;
  margin-right: 20px;
  margin-top: 10px;
}
.footerBtn:hover {
  background-color: #4ec0e4;
  color: #fff;
}
