.columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.description {
  margin-top: 20px;
  margin-bottom: 16px;
}
.publicationsWrap {
  margin-top: 60px;
  display: grid;
  align-items: start;
  gap: 24px;
  grid-template-areas:
    "posts posts posts filters";
  grid-template-columns: 1fr 1fr 1fr minmax(max-content, 280px);
}
.publicationsWrapOne {
  margin-top: 60px;
  display: grid;
  align-items: start;
  gap: 24px;
  grid-template-areas:
    "posts posts posts filters";
}
.filterArea {
  grid-area: filters;
  max-width: 280px;
  height: 0;
  margin: 0;
  position: absolute;
  right: 0px;
}

.publications {
  grid-area: posts;
  display: flex;
  flex-direction: column;
  gap: 11px;
}
