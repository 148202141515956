.topics {
}

.filterLine {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
}

.filterLine + .filterLine {
  margin-top: 13px;
}

.search {
  flex: 2;
  min-width: 282px;
  flex-shrink: 0;
}
.tab {
  white-space: nowrap;
  flex-grow: 1;
  margin-top: 0;
}
.range,
.source {
  min-width: 260px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.select {
  flex-grow: 1;
}

.label {
  margin-right: 10px;
  /* margin-right: 34px; */
}

.list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.resonans{
  font-size: 14px;
    padding: 0px 7px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: #4ec0e4;
    background: #fff;
    border-color: #4ec0e4;
    line-height: 1.5715;
    border-radius: 10px;
}

.resonans:hover{
  background-color: #fafafa;
}

.subject, .event{
  font-size: 14px;
    padding: 0px 7px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #4ec0e4;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: #fff;
    background: #4ec0e4;
    border-color: #4ec0e4;
    line-height: 1.5715;
    border-radius: 10px;
}

.subject:hover, .event:hover{
  background-color: #4ec0e4;
}

.checkboxes{
  display: flex;
  align-items: center;
}

.restheme{
  font-weight: bold;
  text-align: center;
}
.tab span  {
  border: 1px solid white;
}
.tab input:checked + span{
  border: 1px solid #7a7a7a;
}

@media (max-width: 576px) {
  .resonans{
    width: 100%;
  }
} 