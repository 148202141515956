.topicDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  margin: 0;
}

.topicDescription span {
  color: var(--primary-darker-color);
  font-size: 18px;
  line-height: 16px;
  font-weight: 500;
  white-space: nowrap;
}
