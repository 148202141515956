.pageTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  display: flex;
  align-items: flex-end;
  color: var(--primary-color);
  margin: 30px 0 28px;
}

::selection {
  background: #809778; /* Цвет фона */
  color: #fff; /* Цвет текста */
 }

 @media (max-width: 576px) {
  .pageTitle {
      width: calc(100% - 114px);
      min-width: 170px;
  }
} 
