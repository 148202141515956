.stats {

}
.statsWrap {

}
.statsTitle {
    margin-bottom: 3px;
}
.statsRow {
    display: flex;
    /*justify-content: space-between;*/
    justify-content: flex-start;
    align-items: center;
    background: transparent;
    margin-bottom: 5px;
}
.statsItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.4;
    width: 64px;
}
.statsImageBox {
    width: 16px;
    height: 16px;
    display: flex;
    margin-bottom: 4px;
}
.statsItemImage {
    width: 100%;
    height: auto;
    margin: auto;
}
.statsCounter {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: var(--secondary-text-color);
    text-align: center;
}
