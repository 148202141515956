.panel {
  background: #fff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  background-size: 64px 128px;
  background-repeat: no-repeat;
  background-position: left center;
}

.padding {
  padding: 20px;
  padding-left: 30px;
  padding-top: 25px;
}

.numberContainer {
  display: flex;
}
.number {
  flex-shrink: 0;
  font-size: 48px;
  margin-top: 4px;
  margin-right: 36px;
  line-height: 1;
  text-align: center;
  width: 85px;
  min-width: 74px;
  color: var(--primary-color);
}
.content {
  flex-grow: 1;
}

@media (max-width: 576px) {
  .numberContainer {
    display: block;
  }
}
