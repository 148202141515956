.titleColumns {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
}

.columns {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.description {
  margin-top: 20px;
  margin-bottom: 16px;
  word-break:break-all;
}
.status{
  align-items: center;
  position: relative;
  justify-content: end;
  bottom: 30px;
  display: flex;
}
.counter{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0.5;
  font-size: 12px;
}

.item{
  position: relative;
  left: 18px;
  background: white;
  z-index: 1;
}
