.textButton {
  border: 0;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  background-color: transparent;
  cursor: pointer;
  color: var(--primary-color);
}

.textButton:hover {
  color: var(--primary-darker-color);
}
