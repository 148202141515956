.man {
  width: 64px;
  height: 64px;
  background: var(--decoration-text-color);
  position: relative;
}

.icon {
  position: relative;
  width: 64px;
  height: 64px;
  z-index: 2;
}

.negative {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  background: #e34120;
  z-index: 1;
}
